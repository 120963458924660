import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React, {useState} from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MyButton from "../ui-components/buttons/text/mybutton";
import i18n from "../i18n/i18n";

const CookieConsent = ({onClose}) => {
  const [showMore, setShowMore] = useState(false);
  const [acceptAnalytics, setAcceptAnalytics] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const acceptAllCookies = () => {
    localStorage.setItem('histomania_cookies_accepted', true);
    localStorage.setItem('histomania_consent_googleanalytics', true);
    window.histomaniaConsentGoogleAnalyticsAccepted && window.histomaniaConsentGoogleAnalyticsAccepted();
    onClose();
  }

  const closeDialog = () => {
    if(acceptAnalytics) {
      localStorage.setItem('histomania_consent_googleanalytics', true);
      localStorage.setItem('histomania_cookies_accepted', true);
      window.histomaniaConsentGoogleAnalyticsAccepted && window.histomaniaConsentGoogleAnalyticsAccepted();
    } else {
      localStorage.removeItem('histomania_consent_googleanalytics');
      localStorage.removeItem('histomania_cookies_accepted', false);
    }
    onClose();
  }

  const handleInteraction = () => {
    if (!showDialog) {
      setShowDialog(true);
    }
  }

  return !localStorage.getItem("histomania_cookies_accepted") && (
    <>
      {/* Transparentes Overlay */}
      {!showDialog && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            background: 'transparent'
          }}
          onClick={handleInteraction}
          onTouchStart={handleInteraction}
          onMouseMove={handleInteraction}
        />
      )}

      {/* Cookie-Dialog */}
      {showDialog && (
        <Dialog open={true}>
          <DialogTitle id="alert-dialog-title">{i18n("cookieconsent.0")}</DialogTitle>
          <DialogContent>

        {!showMore && <span>{i18n("cookieconsent.js905029185")}</span>}
        {showMore && <div>
          <h4>{i18n("cookieconsent.1")}</h4>
          {i18n("cookieconsent.2")()}
          <h4>{i18n("cookieconsent.3")}</h4>
          {i18n("cookieconsent.4")}
          <FormControlLabel
              control={
                <Checkbox checked={acceptAnalytics}
                          onChange={()=>setAcceptAnalytics(!acceptAnalytics)}/>
              }
              label={"akzeptieren"}
          />
          <br/>
          {i18n("cookieconsent.5")}
        </div>}

    </DialogContent>
    <DialogActions>
      {!showMore && <MyButton onClick={()=>setShowMore(true)} primary={false}>{i18n("cookieconsent.js352836975")}</MyButton>}
      {showMore && <MyButton onClick={()=>closeDialog()} primary={false}>{i18n("cookieconsent.js243259253")}</MyButton>}
      <MyButton onClick={()=>acceptAllCookies()} autoFocus>{i18n("cookieconsent.js645797755")}</MyButton>
    </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default CookieConsent;
